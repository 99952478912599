<template>
		<v-row>
			<v-col cols="6">
				<v-card>
<!--					<template v-slot:heading>-->
<!--						<div class="text-h3 font-weight-light">-->
<!--							Настройки-->
<!--						</div>-->
<!--					</template>-->
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<v-select
										v-model="params.partner_id"
										v-if="partners.length>0"
										:items="partners"
										:item-text="'title'"
										:item-value="'id'"
										label="Партнёрка"
										placeholder="Партнёрка"
										outlined
								>

								</v-select>
							</v-col>
							<v-col cols="12">
								<v-menu
										ref="orderMenu"
										v-model="orderMenu"
										:close-on-content-click="false"
										:return-value.sync="orderMenu"
										transition="scale-transition"
										offset-y
										min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
												v-model="dateOrderText"
												label="Дата создания заказа"
												:prepend-icon="mdiCalendar"
												readonly
												outlined
                        v-bind="attrs"
												v-on="on"
										>
                      <template v-slot:append-outer>
                        <v-icon color="error" @click="dateOrder = []">{{ mdiBackspace() }}</v-icon>
                      </template>
                    </v-text-field>
									</template>
									<v-date-picker
											v-model="dateOrder"
											no-title
											scrollable
											range
									>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-menu
										ref="orderMenu"
										v-model="confirmMenu"
										:close-on-content-click="false"
										:return-value.sync="confirmMenu"
										transition="scale-transition"
										offset-y
										min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
												v-model="dateConfirmText"
												label="Дата подтверждения заказа"
                        :prepend-icon="mdiCalendar"
												readonly
												outlined
												v-bind="attrs"
												v-on="on"
										>
                      <template v-slot:append-outer>
                        <v-icon color="error" @click="dateConfirm = []">{{ mdiBackspace() }}</v-icon>
                      </template>
                    </v-text-field>

									</template>
									<v-date-picker
											v-model="dateConfirm"
											no-title
											scrollable
											range
									>
									</v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12">
								<v-select
										v-model="params.statuses"
										:items="statuses"
										multiple
										outlined
										label="Статусы"
										placeholder="Статусы"
								>
								</v-select>
							</v-col>
							<v-col cols="6">
								<v-select
										v-model="params.order"
										:items="orderTypes"
										label="Сортировка"
										placeholder="Сортировка"
										outlined
								>
								</v-select>
							</v-col>
							<v-col cols="6">
								<v-select
										v-model="params.direction"
										:items="[{text: 'По возрастанию', value: 'asc'}, {text: 'По убыванию', value: 'desc'}]"
										label="Направление"
										placeholder="Направление"
										outlined
								>
								</v-select>
							</v-col>
<!--							<v-col cols="12">-->
<!--								<v-select-->
<!--										v-model="selectedColumns"-->
<!--										:items="columns"-->
<!--										multiple-->
<!--										label="Колонки"-->
<!--										placeholder="Колонки"-->
<!--										return-object-->
<!--										outlined-->
<!--										:menu-props="{ maxHeight: '1000px' }"-->
<!--								>-->
<!--								</v-select>-->
<!--							</v-col>-->
							<v-col cols="4">
								<v-btn color="primary" :loading="grabInProgress" @click="get()">Запросить данные</v-btn>
							</v-col>
							<v-col cols="12">
								<v-progress-linear
										v-if="grabInProgress"
										indeterminate
										height="20"
										color="green"
								></v-progress-linear>
								<a href="https://backend.telestatic.ru/upload/csv/aep_orders.csv" target="_blank">
                  <v-btn v-if="grabCompleted" color="success" Ждщ>
                    <v-icon>{{mdiDownload}}</v-icon><span class="ml-2">Скачать</span>
                  </v-btn>
                </a>
							</v-col>
						</v-row>
					</v-card-text>
        </v-card>
			</v-col>
			<v-col cols="6">
				<v-row>
					<v-col cols="6">
						<v-card>
							<template v-slot:heading>
								<div class="text-h3 font-weight-light">Поля</div>
							</template>
              <v-card-text>
                <v-btn color="primary" @click="selectPreset()">Пресет</v-btn>
                <!--							<v-checkbox v-for="(column, i) in columns" :key="i" :label="column.text" :true-value="selectedColumns.find(x=>x.value===column.value)" dense @click="selectColumn(column)"></v-checkbox>-->
                <v-checkbox v-model="selectedColumns" v-for="(column, i) in columnsLocal" :key="i" :label="column.text" :value="column.value" multiple></v-checkbox>
              </v-card-text>

            </v-card>
					</v-col>
				</v-row>

			</v-col>
		</v-row>
</template>

<script>
import { mdiBackspace, mdiCalendar, mdiCancel, mdiDownload } from '@mdi/js'
import _ from 'lodash'
import Pusher from 'pusher-js'
import httpClient from '../../services/http.service'
export default {
  name: 'AepPostbacks',
  data(){
    return{
      mdiCalendar, mdiCancel, mdiDownload,
      partners: [],
      sources: ['local', 'aep'],
      source: 'local',
      orderMenu: false,
      confirmMenu: false,
      dateOrder: [],
      dateConfirm: [],
      statuses: ['waiting', 'completed', 'rejected'],
      orderTypes: [{text: 'Дата заказа', value: 'order_time'}, {text: 'Дата подтверждения', value: 'order_time_completed'}, {text: 'Номер заказа', value: 'aep_id'}, {text: 'Стоимость товара', value: 'price'},
        {text: 'Заработок', value:'commission'}, {text: 'Название продукта', value: 'product'}, {text: 'Статус заказа', value: 'status'}],
      // columns: [{text: 'Заработок', value: 'commissionUser'}, {text: 'Страна', value: 'country'}, {text: 'Валюта', value: 'currency'}, {text: 'Дата подтверждения', value: 'dateConfirm'}, {text: 'Дата заказа', value: 'dateOrder'}, {text: 'Аффилиатный', value: 'isAffiliateItem'},
      // 	{text: 'Горячий товар', value: 'isHotProduct'}, {text: 'Номер заказа Ali', value: 'orderId'}, {text: 'Тоже номер заказа', value: 'orderNumber'}, {text: 'Статус', value: 'orderStatus'}, {text: 'orderType', value: 'orderType'}, {text: 'Платформа', value: 'platform'},
      // 	{text: 'Цена товара', value: 'price'}, {text: 'Сумма заказа', value: 'turnover'}, {text: 'Название товара', value: 'product'}, {text: 'Ссылка на товар', value: 'productLink'}, {text: 'click_id', value: 'userClickId'}, {text: 'SUB', value: 'userParamSub'},
      // 	{text: 'SUB1', value: 'userParamSub1'}, {text: 'SUB2', value: 'userParamSub2'}, {text: 'SUB3', value: 'userParamSub3'}, {text: 'SUB4', value: 'userParamSub4'}, {text: 'SUB5', value: 'userParamSub5'}, {text: 'ID менеджера', value: 'userUserSubId'}],
      columnsLocal: [{text: 'Заработок (общий)', value: 'commission'}, {text: 'Базовый заработок', value: 'commission_basic'}, {text: 'Бонусный заработок', value: 'commission_bonus'}, {text: 'Страна', value: 'country_code'}, {text: 'Валюта', value: 'currency'}, {text: 'Дата подтверждения', value: 'order_time_completed'}, {text: 'Дата заказа', value: 'order_time'}, {text: 'Аффилиатный', value: 'is_aff'},
        {text: 'Горячий товар', value: 'is_hot'}, {text: 'Номер заказа Ali', value: 'ali_id'}, {text: 'Тоже номер заказа', value: 'aep_id'}, {text: 'Статус', value: 'status'}, {text: 'orderType', value: 'order_type'}, {text: 'Платформа', value: 'platform'},
        {text: 'Цена товара', value: 'price'}, {text: 'Сумма заказа', value: 'order_sum'}, {text: 'Название товара', value: 'title'}, {text: 'Ссылка на товар', value: 'ali_product_url'}, {text: 'click_id', value: 'click_id'}, {text: 'SUB', value: 'sub'},
        {text: 'SUB1', value: 'sub1'}, {text: 'SUB2', value: 'sub2'}, {text: 'SUB3', value: 'sub3'}, {text: 'SUB4', value: 'sub4'}, {text: 'SUB5', value: 'sub5'}, {text: 'ID менеджера', value: 'user_sub_id'}],
      params: {statuses: ['waiting', 'completed', 'rejected'], partner_id: 1},
      selectedColumns: [],
      grabInProgress: false,
      grabCompleted: false,
    }
  },
  watch:{
    dateOrder(v){
      this.params.dateOrderStart = v[0]
      this.params.dateOrderEnd = v[1]
    },
    dateConfirm(v){
      this.params.dateConfirmStart = v[0]
      this.params.dateConfirmEnd = v[1]
    }
  },

  created () {
    this.$store.dispatch('getPartners').then((data)=>{
      this.partners = data
    })
  },
  computed:{
    dateOrderText(){
      return this.dateOrder.join(' - ')
    },
    dateConfirmText() {
      return this.dateConfirm.join(' - ')
    }
  },
  mounted() {
    // Pusher.logToConsole = true
    var pusher = new Pusher('66fca4ca24f68af58e65', {
      cluster: 'eu'
    })
    var channel = pusher.subscribe('reports')
    channel.bind('aep', data => {
      console.log('aep: ', data)
      this.$toast(data.message)
    })
    channel.bind('aep_finished', data => {
      console.log('aep_finished: ', data)
      this.$toast('Готово, бро')
      this.grabCompleted = true
      this.grabInProgress = false
    })
    this.selectPreset()
  },
  methods:{
    mdiBackspace() {
      return mdiBackspace
    },
    selectPreset(){
      //лютая еботня ради красивых и удобных галочек (всё решилось бы v-selectом, но он хуево работает когда много строк)
      this.selectedColumns = []
      _.each(['ali_product_url', 'order_time', 'order_time_completed', 'price', 'order_sum', 'commission', 'sub', 'status', 'ali_id', 'aep_id', 'title', 'commission_basic', 'commission_bonus'], column =>{
        const index = this.columnsLocal.findIndex(x=>x.value===column)
        console.log('column:', column, 'index: ', index)
        if (index >= 0){
          this.selectedColumns.push(this.columnsLocal[index].value)
        }

      })
    },
    selectColumn(column){
      console.log(column)
      if (this.source === 'local'){
        if (this.selectedColumns.find(x=>x.value === column.value)){
          console.log('yes!', this.selectedColumns.findIndex(x=>x.value === column.value))
          this.selectedColumns.splice(this.selectedColumns.findIndex(x=>x.value === column.value), 1)
        }else{
          console.log('no!')
          this.selectedColumns.push(column)
        }
      }
    },
    validate(){
      let success = true
      if (this.selectedColumns.length < 1){
        this.$toast('Выберите хотя бы одну колонку!')
        success = false
      }
      if (this.params.dateOrderStart && this.params.dateOrderEnd){
        if (this.params.dateOrderStart > this.params.dateOrderEnd){
          this.$toast('Дата начала не может быть больше даты окончания!')
          success = false
        }
      }
      if (this.params.dateConfirmStart && this.params.dateConfirmEnd){
        if (this.params.dateConfirmStart > this.params.dateConfirmEnd){
          this.$toast('Дата начала не может быть больше даты окончания!')
          success = false
        }
      }
      return success
    },
    get(){
      // if (this.params.)
      if (!this.validate()){
        return false
      }

      const columns = []
      this.params.columns = []
      console.log('selected columns: ', this.selectedColumns)
      _.each(this.selectedColumns, column=>{
        const col = this.columnsLocal.find(x=>x.value===column)
        console.log('push col: ', col)
        columns.push(col)
      })
      console.log('columns: ', columns)
      // return 1
      this.grabInProgress = true
      this.grabCompleted = false
      httpClient.post('partners/aep-postbacks?params=' + JSON.stringify(this.params), JSON.stringify(columns)).then(({data})=>{
        this.grabInProgress = false
        this.grabCompleted = true
        this.answer = data
      })
    },
  }
}
</script>

<style scoped>

</style>
